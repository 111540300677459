import '../scss/styles.scss';
import * as bootstrap from 'bootstrap';

(() => {
  const darkModeSwitch = document.querySelector('#darkModeSwitch');

  darkModeSwitch?.addEventListener('change', () => {
    document.documentElement.setAttribute('data-bs-theme', darkModeSwitch.checked ? 'dark' : 'light');
  });
})();
